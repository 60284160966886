import React from "react"
import styled from "styled-components"

const Anim = styled.div`

position: relative;
top: 0;
right: 0;
bottom: 0;
left: 0;
display: flex;
justify-content: center;
align-items: center;
min-height:100px;

.spinnit {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .loading {
        width: 3rem;
        height: 3rem;
        margin: 3rem auto;
        transform: rotate(0deg) scale(1);
        animation: loader 1.5s linear infinite;
        border: 2px dashed ${props => props.theme.colors.primary};
        position: relative;
        border-radius: 50%;
    }

}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

`

const Loader = () => {

    return (

        <Anim><div className="spinnit"><div className="loading"></div></div></Anim>

    )

}

export default Loader
