import React from "react"
import Icon from "../../utils/icon"
import styled from "styled-components"

const Rating = styled.div.attrs({
  className: "starRating",
})`
  grid-area: starRating;
  display: flex;
  align-items:center;
  height:100%;

  .svgWrapper{
    margin: 0!important;
    height:22px;
    padding:0 0.1rem;
    
      &.yes{
        path {
          fill: ${props => props.theme.colors.secondary};
        }
        &.gold{
           path {
            fill: #978454;
           }
        }
        &.silver{
           path {
            fill: #A7A8A9;
           }
        }
        &.orange{
           path {
            fill: #FD5000;
           }
        }
      }

      &.no{
        path {
          fill: ${props => props.theme.colors.lightGrey};
        }
      }

  }
`
const StarRating = props => {

  const { rating, starColor = "default" } = props

  if (rating !== null || rating !== undefined) {

    return (

      <Rating>
        {[...Array(5)].map((e, i) => (
          <Icon
            key={i}
            name="star"
            className={rating > i ? `yes ${starColor.toLowerCase()}` : "no"}
            width="1.3rem"
          />
        ))}
      </Rating>

    )

  }

}

export default StarRating
