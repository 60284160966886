import React, { useState } from "react";
import styled from "styled-components";
import StarRating from "../reviews/starRating"
import { Typography, Grid, Box, Button, Collapse } from "@material-ui/core"
import InfoIcon from '@material-ui/icons/Info';
import Icon from "../../utils/icon"
import qt from "../../images/qt-scl-marque-2022-rgb.png"
import Gold3Stars from "../../images/qt-sweetcombe-marque-3-star-gold-rgb.png"
import Orange3Stars from "../../images/qt-sweetcombe-marque-3-star-orange-rgb.png"
import Silver3Stars from "../../images/qt-sweetcombe-marque-3-star-silver-rgb.png"
import Gold4Stars from "../../images/qt-sweetcombe-marque-4-star-gold-rgb.png"
import Orange4Stars from "../../images/qt-sweetcombe-marque-4-star-orange-rgb.png"
import Silver4Stars from "../../images/qt-sweetcombe-marque-4-star-silver-rgb.png"
import Gold5Stars from "../../images/qt-sweetcombe-marque-5-star-gold-rgb.png"
import Orange5Stars from "../../images/qt-sweetcombe-marque-5-star-orange-rgb.png"
import Silver5Stars from "../../images/qt-sweetcombe-marque-5-star-silver-rgb.png"

const Wrapper = styled.div`
    display:flex;
  flex-wrap: wrap;
  .qtg{
      max-height:8rem;
      max-width: 8rem;  
  }
  
  .svgWrapper.yes path {
      fill: #ffde00;
  }
`
const RatingCommentsWraper = styled(Grid)`
   margin-bottom: 2rem;
`
const CommentsWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
    
    background-color: white;
    border: 1px solid ${props => props.theme.colors.lightestGrey};
    padding: 1rem 2rem;
    order: 2;
    @media ${props => props.theme.mq.md} {
        margin-top: 0 !important;
        padding: 1rem 2rem;
        order: unset;
    }    
`

const StarRatingWrapper = styled(Grid)`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    order: 0;
    padding: 1rem 1rem;
    .starRating{
        height: auto !important;
        align-items: center;
        justify-content: center;
    }
    @media ${props => props.theme.mq.md} {
        order: unset;
        background-color: ${props => props.theme.colors.lightestGrey};
    }
`
const Info = styled(Grid)`
    display: grid;
    grid-template-columns: 105px 1fr;
    grid-column-gap: 2rem;
    margin-bottom: 2rem;
    .starRating{
        grid-area: unset;
    }
    
`
const Key = styled(Grid)`
    display: grid;
    grid-template-columns: 21px 1fr;
    grid-column-gap: 2rem;
    margin-bottom: 2rem;
    .starRating{
        grid-area: unset;
    }
    
`

const IconWrapper = styled(Icon)`
    &.gold{
           path {
            fill: #978454;
           }
        }
        &.silver{
           path {
            fill: #A7A8A9;
           }
        }
        &.orange{
           path {
            fill: #FD5000;
           }
        }
`

const PropertyGrading = ({ attrs, pros, cons }) => {

    const [open, setOpen] = useState(false)

    function veRating(attrs) {

        const gradings = attrs["Quality In Tourism Grading"] ? attrs["Quality In Tourism Grading"] : []

        const renderImages = gradings.map((grade, index) => {

            const values = grade.name.split(" ");

            const imageMatrix = {
                "gold3": Gold3Stars,
                "orange3": Orange3Stars,
                "silver3": Silver3Stars,
                "gold4": Gold4Stars,
                "orange4": Orange4Stars,
                "silver4": Silver4Stars,
                "gold5": Gold5Stars,
                "orange5": Orange5Stars,
                "silver5": Silver5Stars,
            }

            return (
                <>
                    <RatingCommentsWraper key={grade.name} container spacing={0}>
                        <StarRatingWrapper item xs={6} md={3}>
                            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" mb={1}>
                                <Box mt={2} mb={1}>
                                    {values[2] && <img className="qtg" alt={`Quality in tourism grade - ${grade.name}`} src={imageMatrix[`${values[2].concat(values[0]).toLowerCase()}`]} />}
                                </Box>
                                <Button variant="text"
                                    color="secondary"
                                    onClick={e => setOpen(!open)}
                                    endIcon={<InfoIcon />}>Quality rating {cons}</Button>

                            </Box>

                        </StarRatingWrapper>
                        <CommentsWrapper item xs={12} md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle" color="secondary">Assessors Comment:</Typography>
                            </Box>
                            <Typography variant="body1" color="text-primary">{pros}</Typography>
                        </CommentsWrapper>
                        <Grid item xs={6} md={3}>
                            <Box display="flex" justifyContent="center" style={{ height: "100%" }} mt={4}>
                                <img className="qtg" alt="Quality in tourism logo" src={qt} />
                            </Box>
                        </Grid>
                    </RatingCommentsWraper>


                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                            <Box mb={6} >
                                <Collapse in={open} collapsedSize={0}>
                                    <Grid container spacing={2}>
                                        <Info item xs={12} md={6}>
                                            <StarRating rating={2} starColor={values[2]} />
                                            <Typography variant="body2">Exceeds guests minimum requirements</Typography>

                                            <StarRating rating={3} starColor={values[2]} />
                                            <Typography variant="body2">A good level of quality &amp; facilities</Typography>

                                            <StarRating rating={4} starColor={values[2]} />
                                            <Typography variant="body2">Consistently high levels of quality & facilities</Typography>

                                            <StarRating rating={5} starColor={values[2]} />
                                            <Typography variant="body2">Exceptional quality throughout</Typography>
                                        </Info>
                                        <Key item xs={12} md={6}>
                                            <IconWrapper
                                                name="star"
                                                className="orange"
                                                width="1.3rem"
                                            />
                                            <Typography variant="body2">Orange - Standard level of quality within their star rating</Typography>

                                            <IconWrapper
                                                name="star"
                                                className="silver"
                                                width="1.3rem"
                                            />
                                            <Typography variant="body2">Silver - Superior level of quality within their star rating</Typography>

                                            <IconWrapper
                                                name="star"
                                                className="gold"
                                                width="1.3rem"
                                            />
                                            <Typography variant="body2">Gold - Supreme level of quality within their star rating</Typography>
                                        </Key>
                                    </Grid>
                                </Collapse>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            )
        })
        return (
            renderImages
        )

    }

    return (
        <Wrapper>
            <div className="veWrapper">
                {veRating(attrs)}

            </div>
        </Wrapper>
    )

}


export default PropertyGrading;