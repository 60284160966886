import React, { useContext } from "react"
import styled from "styled-components"
import Icon from "../../utils/icon"
import { WishListContext } from "../../utils/wishListProvider"

const Wrapper = styled.div.attrs({ className: "wishlist" })`
  svg {
    transition: all 0.3s ease;
    &:hover {
      cursor: pointer;
      fill: ${props => props.theme.colors.primary}!important;
    }
  }
  .active {
    svg {
      fill: ${props => props.theme.colors.primary}!important;
    }
  }
`

const WishList = props => {
    const { wishList, handleWishlist } = useContext(WishListContext)

    const updateWishlist = (e, property) => {
        if (e) {
            e.preventDefault()
        }
        const updateRef = property.propRef

        if (wishList.includes(property.propRef.toString())) {
            return handleWishlist({ type: "REMOVE_ITEM", updateRef })
        }
        return handleWishlist({ type: "ADD_ITEM", updateRef })
    }

    return (
        <Wrapper onClick={e => updateWishlist(e, props)}>
            <span
                className={wishList.includes(props.propRef && props.propRef.toString()) ? "active" : ""}
            >
                <Icon name="heart" height="19px" />
            </span>
        </Wrapper>
    )
}

export default WishList