import React from 'react';
import styled from "styled-components"
import Img from "gatsby-image"
import Icon from "../../utils/icon";
import Slider from "react-slick"
import "../../theme/components/slickSliderStyles/slick.scss"
import "../../theme/components/slickSliderStyles/slick-theme.scss"

const Wrapper = styled.div`

    .slick-slider{

        .slick-next, .slick-prev {
            z-index: 1;
            background-color: rgba(255, 255, 255, 0.9);
            border-radius: 50%;
            width:2rem;
            height:2rem;
        
            &:before{
                content:"";
            }
        }

        .slick-next {
            right: 1rem;
            .svgWrapper{
                margin-right: -2px;
            }
        }

        .slick-prev {
            left: 1rem;
            .svgWrapper{
                margin-left: -2px;
            }
        }

    }

`



const Slide = styled.div`
    position: relative;
    min-height: 16rem;

    .imageWrapper{
        height: 100%;
    }

    .justImg{
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center center;
    }



`

function NextArrow(props) {
    const { className, onClick } = props
    return (
        <button className={className} onClick={onClick}>
            <Icon name="slider-right" width="1rem" />
        </button>
    )
}

function PrevArrow(props) {
    const { className, onClick } = props
    return (
        <button className={className} onClick={onClick}>
            <Icon name="slider-left" width="1rem" />
        </button>
    )
}

const settings = {
    lazyLoad: true,
    infinite: true,
    dots: false,
    arrows: true,
    fade: false,
    slidesToScroll: 1,
    pauseOnHover: true,
    adaptiveHeight: false,
    mobileFirst: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
}

const Gallery = ({ content }) => {

    return (

        <Wrapper>

            <Slider {...settings}>
                {content.length && content.map((item, i) =>
                    <Slide key={i}>

                        {item.localFile ? (
                            <div className="imageWrapper">
                                <Img alt={item.description} fluid={item.localFile.childImageSharp.fluid} />
                            </div>
                        ) : (
                                <img alt={item.description} src={`${item.url}?width=600&scale=both&quality=70`} width="600" className="justImg" />
                            )}

                    </Slide>
                )}
            </Slider>

        </Wrapper>

    )
}

export default Gallery