import React from "react"
import styled from "styled-components"
import WishList from "../wishlist/wishlist"
import { Link } from "gatsby"
import Icon from "../../utils/icon"
import Gallery from "./propertyTileGallery"
//import StarRating from "../reviews/starRating"

import PropertyGrading from "../propertyPage/propertyGrading"

const Wrapper = styled.div.attrs({
  className: "tileWrapper",
})`
  animation: fadeIn 0.5s;

  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(1%);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  box-shadow: 3px 4px 8px #00000029;
  height: 100%;
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: min-content auto;
  grid-template-areas:
    "gallery"
    "content";

  .tileContent {
    grid-area: content;
    display: flex;
    flex-direction: column;
    padding: 1rem;
    position: relative;
    @media ${(props) => props.theme.mq.md} {
      padding: 1.5rem;
    }

    .wishlist {
      position: absolute;
      top: 1.9rem;
      right: 1.5rem;
      width: fit-content;

      .svgWrapper {
        fill: rgba(255, 255, 255, 0.9);
        stroke: ${(props) => props.theme.colors.primary};
        stroke-width: 2px;
        stroke-linejoin: round;
      }
    }

    h4 {
      ${(props) => props.theme.fonts.primaryFont};
      text-transform: uppercase;
      font-weight: 700;
      margin: 0 2rem 0 0;
    }

    .location {
      margin-bottom: 1rem;
      color: ${(props) => props.theme.colors.secondary};
    }

    .starRating {
      margin-bottom: 0.5rem;
      height: fit-content;
    }

    .attributes {
      margin: 1rem 0;
      ${(props) => props.theme.fonts.smallerFontSize};

      span {
        margin: 0 1rem 0.5rem 0;
        display: inline-block;
      }

      .svgWrapper {
        display: inline-block;
        margin-right: 0.25rem;
        fill: ${(props) => props.theme.colors.secondary};
        position: relative;
        top: 1px;
      }
    }

    .price {
      margin-top: 1rem;
      strong {
        color: ${(props) => props.theme.colors.secondary};
      }
    }

    .base{
      margin-top: auto;
      display:flex;
      justify-content: space-between;

      .button {
        ${(props) => props.theme.buttons.primary};
        margin-top: auto;
      }

      .veWrapper{
          margin:0;
          max-height: 6rem;
          max-width: 12rem;
        .ve{
          margin:0;
          max-height: 6rem;
          max-width: 6rem;
        }
      }

    }


  }

  a {
    text-decoration: none;
  }
`
const TileGallery = styled.div.attrs({
  className: "tileGallery",
})`
  grid-area: gallery;
  overflow: hidden;
  position: relative;

  .hasOffer{
      z-index:2;
      background-color: ${(props) => props.theme.colors.primary};
      color: white;
      padding:0.25rem;
      text-align:center;
      transform: rotate(-45deg);
      position: absolute;
      left: -50px;
      top: 30px;
      width: 200px;
    }
`

const PropertyTile = React.memo(({ props }) => {
  //is there WP gallery images
  const whichGallery = () => {
    if (props.gallery != null) {
      return props.gallery
    } else {
      return props.images
    }
  }

  //round up price
  function roundUp() {
    const price = Math.ceil(props.prices.priceBase)
    return price
  }

  //find attribute value
  function findAttribute(attr) {
    let attrValue = null
    if (props.attributes) {
      const attrMatch = props.attributes.filter(
        (attribute) => attribute.name === attr
      )
      attrValue = attrMatch.length ? Boolean(attrMatch[0].value) : null
    }
    return attrValue
  }


  const ignore = ["Visit England Grading"]
  const attrs = props.attributes.reduce((r, attr) => {
    if (ignore.indexOf(attr.category) === 0) {
      if (r[attr.category] === undefined) r[attr.category] = []
      r[attr.category].push(attr);
    }
    return r;
  }, {})

  const propertyUrl = props.wpurl;

  return (
    <Wrapper>
      <TileGallery>
        {props.offers && props.offers.length > 0 && (
          <div className="hasOffer">Special Offer</div>
        )}
        <Link to={propertyUrl} title={props.name}>
          <Gallery content={whichGallery()} />
        </Link>
      </TileGallery>

      <div className="tileContent">

        <WishList {...props} />

        <Link to={propertyUrl} title={props.name}>
          <h4>{props.name}</h4>
        </Link>

        <div className="location">{props.where.town}</div>

        {`${props?.description?.short?.substring(0, 80)}...`}

        <div className="price">
          From <strong>£{roundUp()}</strong> pw
        </div>

        <div className="attributes">
          <span>
            <Icon name="sleeps" height="0.75rem" /> Sleeps {props.sleeps}
          </span>

          <span>
            <Icon name="beds" height="0.75rem" /> Bedrooms {props.bedrooms}
          </span>

          <span>
            <Icon name="bathroom" height="0.75rem" /> Bathrooms {props.bathrooms}
          </span>

          {props.pets && (
            <span className="lowerIcon">
              <Icon name="pets-welcome" height="0.75rem" /> Pets Welcome
            </span>
          )}

          {findAttribute("Car Parking") && (
            <span className="lowerIcon">
              <Icon name="parking" height="0.75rem" /> Parking
            </span>
          )}

          {findAttribute("Garden") && (
            <span className="lowerIcon">
              <Icon name="garden" height="0.75rem" /> Garden
            </span>
          )}

          {findAttribute("Internet") && (
            <span>
              <span className="lowerIcon">
                <Icon name="wifi" height="0.75rem" /> WiFi
              </span>
            </span>
          )}
        </div>

        <div className="base">

          <Link className="button" to={propertyUrl} title={props.name}>
            View Property
          </Link>

          <PropertyGrading attrs={attrs} />

        </div>

      </div>
    </Wrapper>
  )
})

export default PropertyTile
